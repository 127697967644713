<template>
	<App title="Stacs Vault">
		<div class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Vault Details</h3>
					</template>
					<div class="row">
						<div class="col-8">
							<TextBlock label="Title">
								{{ vault.title }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="User">
								<a class="text-accent1" :href="vault.user.url.single">{{ vault.user.fullname }}</a>
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Amount">
								{{ vault.amount.formatted }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Income">
								{{ vault.income.formatted }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Interest Rate">
								{{ vault.interest.data }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Balance">
								{{ vault.balance.formatted }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Earned">
								{{ vault.earned.formatted }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Created Date">
								{{ $render.date(vault.created_at) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Maturity Date">
								{{ $render.date(vault.yielded_at) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Completed Date">
								{{ $render.date(vault?.completed_at) }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Withdrawn">
								{{ $render.bool(vault.hasWithdrawn) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Extended">
								{{ $render.bool(vault.isExtended) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Locked">
								{{ $render.bool(vault.isLocked) }}
							</TextBlock>
						</div>
					</div>
					<div v-if="vault.isLocked" class="row">
						<div class="col-8">
							<TextBlock label="Lock Remark">
								{{ vault.locked_remark }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Locked date">
								{{ $render.date(vault.locked_at) }}
							</TextBlock>
						</div>
					</div>
					<template #footer>
						<button v-if="!vault.isCompleted" class="btn btn-tertiary text-danger" @click="onComplete">Complete Plan</button>
						<button v-if="vault.isExtendable" class="btn btn-tertiary text-warning" @click="onExtend">Extend Plan</button>
						<button v-if="vault.isLocked" class="btn btn-tertiary text-success" @click="onLock($event, 'unlock')">Unlock Plan</button>
						<button v-else class="btn btn-tertiary text-danger" @click="onLock($event, 'lock')">Lock Plan</button>
					</template>
				</Block>
			</div>
		</div>
		<TransactionTable :model="vault" />
		<LockerBox ref="locker-modal" :plan="vault" />
	</App>
</template>
<script lang="ts">
import type Vault from '@/models/Vault'
import LockerBox from '@/pages/snippets/LockerBox.vue'
import TransactionTable from '@/pages/snippets/TransactionTable.vue'
import Block from '@/vue/components/Block.vue'
import Card from '@/vue/components/Card.vue'
import TextBlock from '@/vue/components/TextBlock.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import { Component, Prop, Ref, toNative, Vue } from 'vue-facing-decorator'

@Component({
	components: {
		App,
		Card,
		Block,
		TextBlock,
		LockerBox,
		TransactionTable,
	},
})
class Single extends Vue {
	@Prop({ type: Object })
	readonly vault!: Vault

	@Ref('locker-modal')
	readonly locker!: InstanceType<typeof LockerBox>

	onComplete(e: Event) {
		e.preventDefault()
		const button = e.target as HTMLButtonElement
		button.disabled = true
		const label = button.innerHTML
		button.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'

		this.$sweet
			.fire({
				title: 'Are You Sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',

				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'YES',
			})
			.then((result) => {
				if (result.isConfirmed) {
					this.$http
						.patch(this.route('vault.ajax.complete', { vault: this.vault.slug }))
						.then(() => this.$router.reload({ only: ['vault'] }))
						.catch((e: AxiosError) => (this.$error(e), (button.disabled = false), (button.innerHTML = label)))
				} else {
					button.disabled = false
					button.innerHTML = label
				}
			})
	}
	onExtend(e: Event) {
		e.preventDefault()
		const button = e.currentTarget as HTMLButtonElement
		button.disabled = true
		const label = button.innerHTML
		button.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
		this.$sweet
			.fire({
				title: 'Are You Sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',

				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'YES',
			})
			.then((result) => {
				if (result.isConfirmed) {
					this.$http
						.patch(this.route('vault.ajax.extend', { vault: this.vault.slug }))
						.then(() => this.$router.reload({ only: ['vault'] }))
						.catch((e: AxiosError) => (this.$error(e), (button.disabled = false), (button.innerHTML = label)))
				} else {
					button.disabled = false
					button.innerHTML = label
				}
			})
	}

	onLock(e: Event, type: string) {
		e.preventDefault()

		const button = e.target as HTMLButtonElement
		button.disabled = true
		const label = button.innerHTML
		button.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'

		if (type === 'unlock') {
			this.$sweet
				.fire({
					title: 'Are You Sure?',
					text: "You won't be able to revert this!",
					icon: 'warning',

					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'YES',
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.$http
							.patch(this.route('vault.ajax.lock', { vault: this.vault.slug }), { type: 'unlock' })
							.then(() => this.$router.reload({ only: ['vault'] }))
							.catch((e: AxiosError) => (this.$error(e), (button.disabled = false), (button.innerHTML = label)))
					} else {
						button.disabled = false
						button.innerHTML = label
					}
				})
		} else {
			this.locker.modal.onToggleModal()
		}
	}
}

export default toNative(Single)
</script>
