<template>
	<Field v-slot="{ field, errors }" :name="name" :rules="rules" :label="label">
		<div class="form-floating mb-2">
			<Phone v-bind="{ ...$attrs, ...field }" :options="options" />
			<Errors :errors="errors" />
		</div>
	</Field>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, toNative } from 'vue-facing-decorator'
import Errors from './Errors.vue'
import Field from './Field.vue'
import Phone from './Phone.vue'

@Component({
	components: {
		Errors,
		Field,
		Phone,
	},
	inheritAttrs: false,
	emits: ['update:modelValue'],
})
class PhoneField extends Vue {
	declare $attrs: {
		id?: string
	}

	phone!: intlTelInput.Plugin

	@Ref('phone')
	readonly input!: HTMLInputElement

	@Prop({ type: Object, default: () => {} })
	readonly options!: object

	@Prop({ type: String })
	readonly name!: string

	@Prop({ type: [String, Object] })
	readonly rules!: string | object

	@Prop({ type: String })
	readonly label!: string

	@Prop({ type: String, default: '' })
	readonly placeholder!: string

	// @Model({ type: String })
	//readonly value!: string
}

export default toNative(PhoneField)
</script>
