<template>
	<App title="Stacs Zero">
		<div class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h1 class="block-title">Zero - #{{ zero.id }}</h1>
					</template>
					<div class="row">
						<div class="col">
							<TextBlock label="User">
								{{ zero.user.fullname }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Amount">
								{{ $render.money(zero.amount) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Penalty Charge">
								{{ $render.money(zero.penalty) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Premium">
								{{ $render.money(zero.premium) }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Created">
								{{ $render.date(zero.created_at) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Due Date">
								{{ $render.date(zero.due_at) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Completed">
								{{ $render.date(zero.completed_at) }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Default">
								{{ $render.bool(zero.is_default) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Active">
								{{ $render.bool(zero.active) }}
							</TextBlock>
						</div>
					</div>
				</Block>
			</div>
		</div>
		<TransactionTable :model="zero" />
	</App>
</template>
<script lang="ts">
import type Zero from '@/models/ZERO'
import TransactionTable from '@/pages/snippets/TransactionTable.vue'
import Block from '@/vue/components/Block.vue'
import Datatable from '@/vue/components/Datatable.vue'
import TextBlock from '@/vue/components/TextBlock.vue'
import App from '@/vue/templates/App.vue'
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

@Component({
	components: {
		App,
		Block,
		TextBlock,
		Datatable,
		TransactionTable,
	},
})
class Single extends Vue {
	@Prop({ type: Object })
	readonly zero!: Zero
}

export default toNative(Single)
</script>
